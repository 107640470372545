import { useCallback, useEffect, useReducer, useState } from 'react'
import ChatBot from 'react-simple-chatbot';



// function reducer(state, action) {
//     const test =  { messageList: [...state.messageList, {
//         id: 'Registration/Account/Password',
//         options: [
//             { 
//                 value: '1.1', 
//                 label: 'If the registered email for the JC project is changed, how could the teacher receive the latest information?',
//                 trigger: 'welcome' 
//             },
//         ]
//     }]}
//     // console.log('test', test)
//     switch(action.type) {
        
//         case 'Registration/Account/Password':
//             console.log('123')
//             return { messageList: [...state.messageList, {
//                 id: 'Registration/Account/Password',
//                 options: [
//                     { 
//                         value: '1.1', 
//                         label: 'If the registered email for the JC project is changed, how could the teacher receive the latest information?',
//                         trigger: 'welcome' 
//                     },
//                 ]
//             }],}
//         default:
//             return state.messageList
//     }
// }



    // const initialState = { messageList: [{
    //     id: 'welcome',
    //     message: 'Hi, Please select a topic.',
    //     trigger: 'welcomeOptions',
    // }, {
    //     id: 'welcomeOptions',
    //     options: [
    //         { value: 'Registration/Account/Password', label: 'Registration/Account/Password', trigger: ({value, steps}) => dispatch({type: value})},
    //         // { value: 'Registration/Account/Password', label: 'Registration/Account/Password', trigger: ({value, steps}) => handleDispatch({type: value})},
    //         { value: 'Power BI/Dashboard', label: 'Power BI/Dashboard', },
    //         { value: 'DMP Installation & Deployment', label: 'DMP Installation & Deployment', },
    //     ],
    // }]};

    // const [state, dispatch] = useReducer(reducer, initialState);
    // console.log(state)
    // const customReducer = useCallback(({value, steps}) => {
    //     console.log('===', value)
    //     dispatch(value)

    //     // switch(value) {
            
    //     //     case 'Registration/Account/Password':
    //     //         return [...state, {
    //     //             id: '1',
    //     //             options: [
    //     //                 { 
    //     //                     value: '1.1', 
    //     //                     label: 'If the registered email for the JC project is changed, how could the teacher receive the latest information?',
    //     //                     trigger: 'welcome' 
    //     //                 },
    //     //             ]
    //     //         }]
    //     //     default:
    //     //         return state
    //     // }
    // }, [state])
    // const initialState = {messageList: [{
    //     id: '1',
    //     message: 'Hello World',
    //     trigger: '2',
    //   },
    //   {
    //     id: '2',
    //     message: ({ previousValue, steps }) => 'Hello',
    //     trigger: ({ value, steps }) => '3',
    //   },
    //   {
    //     id: '3',
    //     message: 'Bye',
    //     end: true,
    //   }]};


const App = (props) => {
    return (
        <ChatBot
            headerTitle = 'DMP Virtual Assistant'
            placeholder = 'Please select the `Ask a Question` option before typing the message...'
            {...props}
        />
    )
}

export default App;