import { useCallback, useEffect, useReducer, useState } from 'react';
import CustomChatbot from './Chatbot';
import CustomStep from './CustomStep';
import Image from './Image';
import answer1_1 from './static/images/answer1_1.jpg';
import answer1_6 from './static/images/answer1_6.jpg';
import answer2_2_1 from './static/images/answer2_2_1.jpg';
import answer2_3_1 from './static/images/answer2_3_1.jpg';
import answer2_3_3 from './static/images/answer2_3_3.jpg';
import answer2_3_3_1 from './static/images/answer2_3_3_1.jpg';
import answer2_3_3_2 from './static/images/answer2_3_3_2.jpg';
import answer2_3_5 from './static/images/answer2_3_5.jpg';
import answer3_1_6_1 from './static/images/answer3_1_6_1.jpg';
import answer3_1_6_1_1 from './static/images/answer3_1_6_1_1.jpg';
import answer3_1_6_1_2 from './static/images/answer3_1_6_1_2.jpg';
import answer3_1_6_1_2_1 from './static/images/answer3_1_6_1_2_1.jpg';
import answer3_1_6_1_3 from './static/images/answer3_1_6_1_3.jpg';
import answer3_1_6_1_4 from './static/images/answer3_1_6_1_4.jpg';
import answer3_1_6_1_5 from './static/images/answer3_1_6_1_5.jpg';
import answer3_1_6_2 from './static/images/answer3_1_6_2.jpg';
import answer3_1_6_2_1 from './static/images/answer3_1_6_2_1.jpg';
import answer3_1_6_2_2 from './static/images/answer3_1_6_2_2.jpg';
import answer3_1_6_2_2_1 from './static/images/answer3_1_6_2_2_1.jpg';
import answer3_1_6_2_3 from './static/images/answer3_1_6_2_3.jpg';
import answer3_1_6_2_4 from './static/images/answer3_1_6_2_4.jpg';
import answer3_1_6_2_5 from './static/images/answer3_1_6_2_5.jpg';
import answer3_2_1 from './static/images/answer3_2_1.jpg';
import answer3_2_2 from './static/images/answer3_2_2.jpg';
import answer3_3_1 from './static/images/answer3_3_1.jpg';
import answer4_1_3_2 from './static/images/answer4_1_3_2.jpg';
import answer4_1_3_7 from './static/images/answer4_1_3_7.jpg';
import answer4_2_1_1 from './static/images/answer4_2_1_1.jpg';
import answer4_2_1_2 from './static/images/answer4_2_1_2.jpg';
import answer4_4_1_10 from './static/images/answer4_4_1_10.jpg';
import answer4_4_2_1 from './static/images/answer4_4_2_1.jpg';
import answer4_4_2_4_1 from './static/images/answer4_4_2_4_1.jpg';
import answer4_4_2_4_2 from './static/images/answer4_4_2_4_2.jpg';
import answer4_4_2_4_3 from './static/images/answer4_4_2_4_3.jpg';
import answer4_5_1_2_1 from './static/images/answer4_5_1_2_1.jpg';
import answer4_5_1_2_2 from './static/images/answer4_5_1_2_2.jpg';
import answer4_5_1_2_3 from './static/images/answer4_5_1_2_3.jpg';
import answer4_5_1_4 from './static/images/answer4_5_1_4.jpg';
import answer4_5_2_1_1 from './static/images/answer4_5_2_1_1.jpg';
import answer4_5_2_1_2 from './static/images/answer4_5_2_1_2.jpg';
import answer4_5_2_1_3 from './static/images/answer4_5_2_1_3.jpg';
import answer4_5_2_1_4 from './static/images/answer4_5_2_1_4.jpg';
import answer4_5_2_1_5 from './static/images/answer4_5_2_1_5.jpg';
import answer4_5_2_1_6 from './static/images/answer4_5_2_1_6.jpg';
import answer4_5_2_1_7 from './static/images/answer4_5_2_1_7.jpg';
import answer5_1_1 from './static/images/answer5_1_1.jpg';


const Registration_Account_Password_1 = [
    {
        id: 'Registration/Account/Password',
        options: [
            {
                value: '1.1',
                label: 'If the registered email for the JC project is changed, how could the teacher receive the latest information?',
                trigger: 'answer1_1'
            },
            {
                value: '1.2',
                label: 'The SQL Server password is forgotten or lost, how to change?',
                trigger: 'answer1_2'
            },
            {
                value: '1.3',
                label: 'For the ODBC settings, where can one obtain the user and password information?',
                trigger: 'answer1_3'
            },   
            {
                value: '1.4',
                label: '如何加入 MS Team Group Chat?',
                trigger: 'answer1_4'
            },
            {
                value: '1.5',
                label: 'What is the SUID?',
                trigger: 'answer1_5'
            },
            {
                value: '1.6',
                label: '如何加入 WhatsApp Group Chat?',
                trigger: 'answer1_6'
            },
        ]
    }, {
        id: 'answer1_1',
        component: (
            <div>
                <p>After pressing “Edit user permission” in the “Item Analysis” (IAR) module, there was error message “ ‘NoneType’ object has no attribute ‘subject_nm_en’ ”.  Below is the image of this error:</p>
                <Image images={[answer1_1]} />
            </div>
        ),
        trigger: 'welcome',
    }, {
        id: 'answer1_2',
        message: 'Unfortunately, once you set the SQL Server password, it cannot be changed UNLESS you know the old password.  For security reason, it will be vulnerable for data theft if SQL Server password can be changed easily.  As a result, we recommend uninstalling and reinstalling all components related to SQL Server.',
        trigger: 'welcome',
    }, {
        id: 'answer1_3',
        message: 'The School Principal or the authorized person appointed by the Principal should contact its EdB School Liaison Officer (SLO, 學校聯絡主任) directly. The contact information of the SLO is at the following link: https://cdr.websams.edb.gov.hk/聯絡我們/.',
        trigger: 'welcome',
    }, {
        id: 'answer1_4',
        message: '請你把名字、學校名稱、職位、電話號碼和郵箱地址等訊息發到我們 help desk email (jcdm.support@polyu.edu.hk), 我們同事收到後就會邀請你加入 MS Team Group Chat。',
        trigger: 'answer1_4_1',
    }, {
        id: 'answer1_4_1',
        message: 'Group chat 可以有多位老師加入交流與學習，不僅僅只是學校聯絡人，IT staff 也歡迎加入，可以一起分享經驗。',
        trigger: 'welcome',
    }, {
        id: 'answer1_5',
        message: 'SUID = “School Unique ID”. Each school has a unique SUID for identification set by Education Bureau (EdB). We do not know your SUID. The School Principal or the authorized person appointed by the Principal should contact its EdB School Liaison Officer (SLO, 學校聯絡主任) directly. The contact information of the SLO is at the following link: https://cdr.websams.edb.gov.hk/聯絡我們/',
        trigger: 'welcome',
    },{
        id: 'answer1_6',
        component: (
            <div>
                <p>請用你的 WhatsApp相機掃描旁邊二維碼。</p>
                <p>Please scan this QR code with your WhatsApp camera.</p>
                <Image images={[answer1_6]} />
            </div>
        ),
        trigger: 'welcome',
    }
];

const PowerBI_Dashboard_2 = [{
    id: 'Power BI/Dashboard',
    options: [
        {
            value: '2.1',
            label: 'Common Questions',
            trigger: 'common questions'
        },
        {
            value: '2.2',
            label: 'Load & Export Data',
            trigger: 'Load & Export Data',
        },
        {
            value: '2.3',
            label: 'Security Arrangement',
            trigger: 'Security Arrangement'
        },
    ]
}, {
    id: 'common questions',
    options: [
        {
            value: '2.1.1',
            label: 'Can every teacher access the DM Server data easily?',
            trigger: 'answer2_1_1',
        },
        {
            value: '2.1.2',
            label: 'How can one use Power BI files on other computers?',
            trigger: 'answer2_1_2',
        },
        {
            value: '2.1.3',
            label: 'Are Power BI and DM Platform the same?',
            trigger: 'answer2_1_3'
        },
        {
            value: '2.1.4',
            label: '如果還沒有下載 DMP Power BI, 但想試試的話，可以怎麽做呢？',
            trigger: 'answer2_1_4'
        },
        {
            value: '2.1.5',
            label: 'Some SAMS academic year data is missing from Power BI, is this normal?',
            trigger: 'answer2_1_5'
        },
    ]
}, {
    id: 'answer2_1_1',
    message: 'For 	Power BI module, teachers just need to install Power BI (Free!) on their computers.  Then, these up-to-date Power BI files (.pbix) can be copied and pasted to their computers easily.  However, these files are view-only.  If they would like to obtain the latest data, they need to download a copy from the DM Server computer again.',
    trigger: 'answer2_1_1_1',
}, {
    id: 'answer2_1_1_1',
    message: 'For the DM System, teachers would need to install the whole DM System to access the data',
    trigger: 'welcome',
}, {
    id: 'answer2_1_2',
    message: 'Firstly, Power BI needs to be installed on other computers.  Then, the teachers can copy the file(s) from the host computer to the other computers.  These files are read-only',
    trigger: 'welcome',
}, {
    id: 'answer2_1_3',
    message: 'Power BI is a visualization tool for the data, while DMP is a web platform which contains several modules and functions to view and manage data (including some data analysis functions).  Besides, they share the same data source loaded from WebSAMS.',
    trigger: 'welcome',
}, {
    id: 'answer2_1_4',
    component: (
        <div>
            <p>DMP Dashboard 簡單版本鏈接</p>
            <p>請到以下網頁: <a href='https://powerbi.microsoft.com/en-us/landing/signin/' target={'_blank'}>https://powerbi.microsoft.com/en-us/landing/signin/</a></p>
            <p>Login Name: dmp_training@hkrsstpss.edu.hk</p>
            <p>Password: dmp@2019</p>
            <p>選 DM training，就會有 DMP Dashboard home page，就可以直接試試</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer2_1_5',
    message: 'Please contact your WebSAMS coordinator to download WebSAMS data in 2022 using the original method',
    trigger: 'welcome',
}, 
{
    id: 'Load & Export Data',
    options: [
        {
            value: '2.2.1',
            label: "What's the difference between web-based or open-updated Power BI files directly?",
            trigger: 'answer2_2_1',
        },
        {
            value: '2.2.2',
            label: '我們是否可以 export 出來方便分享呢？',
            trigger: 'answer2_2_2',
        },
        {
            value: '2.2.3',
            label: '不能連接 Power BI 到 SQL，應該點做？',
            trigger: 'answer2_2_3'
        },
    ]
}, {
    id: 'answer2_2_1',
    component: (
        <div>
            <p>Power BI 與 DMP 不一樣的，DMP提供的功能和用法不一樣，裡面包含多個Module。</p>
            <Image images={[answer2_2_1]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer2_2_2',
    message: '可以的，可以基於你想要的 data sheet export share 給家長；',
    trigger: 'answer2_2_2_1',
}, {
    id: 'answer2_2_2_1',
    message: '我們 DMP 除了給先生和學校，後續也希望有機會可以給學生或家長，看看與自己相關的資料.',
    trigger: 'welcome',
}, {
    id: 'answer2_2_3',
    message: '用了錯誤網絡或者防火墻封鎖了，要同一個網絡, 才可以連接上server。學校需要在同一個網絡內才能連接，第二點是檢查防火墻配置有沒有封鎖相關port位。',
    trigger: 'welcome',
},
{
    id: 'Security Arrangement',
    options: [
        {
            value: '2.3.1',
            label: "Load 到 DMP 但無資料出嚟, 係 SAMS 拎到資料 (no detail seen in Power BI)",
            trigger: 'answer2_3_1',
        },
        {
            value: '2.3.2',
            label: '我們是否可以 export 出來方便分享呢？',
            trigger: 'answer2_2_2',
        },
        {
            value: '2.3.3',
            label: '本校 DMP Server 應該已經 Set 好, 資料已經由 CloudSAMS 下載於 Server 中, 但係開 Power BI 時無資料顯示, 請問邊到出咗問題?',
            trigger: 'answer2_3_3'
        },
        {
            value: '2.3.4',
            label: '我校認為將 Power BI file 派給同事，讓他們在自己電腦的 Power BI 軟件開啟，會有好高的資料洩漏風險。因此不贊成為同事安裝 Power BI 軟件，想請問有沒有甚麼措施能減少風險呢？',
            trigger: 'answer2_3_4'
        },        
        {
            value: '2.3.5',
            label: 'What is the difference between web-based or open-updated Power BI files directly?',
            trigger: 'answer2_3_5'
        },
    ]
}, {
    id: 'answer2_3_1',
    component: (
        <div>
            <p>需要執行這段code (in “Operation Manual”, section before 7.4) ，沒有匯入到數據庫，Power BI 自然空白一片</p>
            <Image images={[answer2_3_1]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer2_3_3',
    component: (
        <div>
            <p>CloudSAMS 第一次也要做初始化。做了才會有所有學年數據。</p>
            <Image images={[answer2_3_3]}/>
            <p>可以下載 WebSAMs csv 1.1.2 做初始化。</p>
            <Image images={[answer2_3_3_1]}/>
            <Image images={[answer2_3_3_2]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer2_3_4',
    message: '目前只限指定老師使用，一個 WebSAMS 負責人，另一個項目負責人, 只在特定日子供老師瀏覽部分數據, 比如家長日，一年只有兩次。',
    trigger: 'welcome',
}, {
    id: 'answer2_3_5',
    component: (
        <div>
            <p>Power BI 與 DMP Web Portal 不一樣的，DMP Web Portal 提供的功能和用法不一樣，裡面包含多個Module。</p>
            <Image images={[answer2_3_5]}/>
        </div>
    ),
    trigger: 'welcome',
}];

const DMP_Installation_Deployment_3 = [{
    id: 'DMP Installation & Deployment',
    options: [
        {
            value: '3.1',
            label: 'Installation and & Deployment',
            trigger: 'Installation and & Deployment'
        },
        {
            value: '3.2',
            label: 'Versions, Upgrade and & Backup',
            trigger: 'Versions, Upgrade and & Backup',
        },
        {
            value: '3.3',
            label: 'Training Videos',
            trigger: 'Training Videos'
        },
    ],
   
}, {
    id: 'Installation and & Deployment',
    options: [
        { value: '3.1.1', label: 'Is the installation package link invalid?', trigger: 'answer3_1_1' },
        { value: '3.1.2', label: 'If I am not the project coordinator of the school, how can I access the DM project folder? ', trigger: 'answer3_1_2' },
        { value: '3.1.3', label: 'I cannot access the HKJC_DM_Deployment Folder, what should I do?', trigger: 'answer3_1_3' },
        { value: '3.1.4', label: 'What is the difference between the “DMP_Deployment_Folder” and the “SQL_Fix_Folder”?', trigger: 'answer3_1_4' },
        { value: '3.1.5', label: 'There is nothing on the DM platform. What is the first step should I take?', trigger: 'answer3_1_5' },
        { value: '3.1.6', label: 'The website cannot find ‘utils’ and ‘_utils’ from ‘PyPDF2’, what should I do?', trigger: 'answer3_1_6' },
        { value: '3.1.7', label: '我校若未成功 install DMP 或 CloudSAMS connection 有問題，可否直接用到 web-based DMP?', trigger: 'answer3_1_7' },
        { value: '3.1.8', label: '做到 section B step2 第 9 點，行 .bat 檔時顯示已經有 log folder , 可以繼續嗎？', trigger: 'answer3_1_8' },
    ]
}, 
{
    id: 'Versions, Upgrade and & Backup',
    options: [
        { value: '3.2.1', label: 'How can one check the file size of the backup?', trigger: 'answer3_2_1' },
        { value: '3.2.2', label: 'How can one find the installed version number?', trigger: 'answer3_2_2' },
        { value: '3.2.3', label: 'I have not done the previous upgrade yet. Is every upgrade the pre-requisite of the next upgrade (like SAMS), or can I do the latest upgrade only?', trigger: 'answer3_2_3' },
    ]
}, 
{
    id: 'Training Videos',
    options: [
        { value: '3.3.1', label: 'Where are the training videos?', trigger: 'answer3_3_1' },
        { value: '3.3.2', label: '播放的演示片段可否可以給我們？', trigger: 'answer3_3_2' },
        { value: '3.3.3', label: '培訓裏的影片是否可以發給我們？', trigger: 'answer3_3_3' },
        { value: '3.3.4', label: '如果沒有登記，有 link 的話，可以加入 training workshop 嗎？', trigger: 'answer3_3_4' },
        { value: '3.3.5', label: 'Video / 視頻 Version 1.7.5 安裝教學 (YouTube).', trigger: 'answer3_3_5' },
    ]
}, 
{
    id: 'answer3_1_1',
    component: (
        <div>
            <p>If you believe the link is invalid, or have not received the link sent by us, or cannot access the folder, please send an email to <a href = "mailto:jcdm.support@polyu.edu.hk"> jcdm.support@polyu.edu.hk </a> to request the link again.</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer3_1_2',
    message: 'You need to send an email to the JC support email for a request to change your registered email.  Then, you can continue to receive the latest information',
    trigger: 'welcome',
}, {
    id: 'answer3_1_3',
    message: 'You can request the project coordinator in your school, as they are trusted personnel to grant the permission to other teachers.',
    trigger: 'welcome',
}, {
    id: 'answer3_1_4',
    message: 'Scenario 1: For the schools that have already installed the DMP and can run smoothly, they can neglect the SQL_Fix_Folder.',
    trigger: 'answer3_1_4_1',
}, {
    id: 'answer3_1_4_1',
    message: 'Scenario 2: For the schools that have already installed the DMP and have many bugs when using the DMP, they can follow the instructions in SQL_Fix_Folder to update the SQL Schema. Before updating the SQL Schema, they need to back up the SQL Database to prevent the data loss.',
    trigger: 'answer3_1_4_2',
},{
    id: 'answer3_1_4_2',
    message: 'Scenario 3: For the schools that have not installed the DMP before, they can neglect the SQL_Fix_Folder and follow the DMP_Deployment_Folder only.',
    trigger: 'welcome',
},{
    id: 'answer3_1_5',
    message: 'The DM platform should show all modules after logging in.  If there is no data, you can follow our guideline (p77) to load data from WebSAMS to DMP.  If the webpage is blank (or error occurs), it means that the setting up of DMP is unsuccessful.',
    trigger: 'welcome',
},{
    id: 'answer3_1_6',
    component: (
        <div>
            <p>Use python (bat) to fix (need to install PyPDF2 需要安裝)</p>
            <p>After logging-in to PolyU MS Team account and select the “Files” at the top menu bar.  Click “Online Training” folder and choose the workshop that you would like to view.</p>
            <p><u>Scenario 1: </u>Error Message – cannot import name ‘utils’ from ‘PyPDF2’</p>
            <p>Please find <a href="#3_1_6_case1">Solution for Case 1</a></p>
            <Image images={[answer3_1_6_1]}/>
            <p><u>Scenario 2: </u>Error Message – cannot import name ‘_utils’ from ‘PyPDF2’ </p>
            <p>Please find <a href="#3_1_6_case2">Solution for Case 2</a></p>
            <Image images={[answer3_1_6_2]}/>
            
            <br/>
            <p id='3_1_6_case1'><b>Solution for Case 1</b></p>
            <p><b>Please follow the following steps to fix the problem:</b></p>
            <p> 1) Press on the Window button on the keyboard or press the Window icon on the left-bottom of the screen.</p>
            <Image images={[answer3_1_6_1_1]}/>
            <p> 2) Then type in “cmd”. look for the software named “Command Prompt” and right click on the software -> select “Run as administrator”.</p>
            <Image images={[answer3_1_6_1_2, answer3_1_6_1_2_1]}/>
            <p> 3) After the Command Prompt have started, please type in “cd C:\HKJC_DM\django_web\hkjc_dm” and Press Enter.</p>
            <Image images={[answer3_1_6_1_3]}/>
            <p> 4) Then type in “workon hkjc_dm” and Press Enter.</p>
            <Image images={[answer3_1_6_1_4]}/>
            <p> 5) Then type in “pip install PyPDF2==1.26” and Press Enter.</p>
            <Image images={[answer3_1_6_1_5]}/>

            <br/>
            <p id='3_1_6_case2'><b>Solution for Case 2</b></p>
            <p><b>Please follow the following steps to fix the problem:</b></p>
            <p> 1) Press on the Window button on the keyboard or press the Window icon on the left-bottom of the screen.</p>
            <Image images={[answer3_1_6_2_1]}/>
            <p> 2) Then type in “cmd”. look for the software named “Command Prompt” and right click on the software -> select “Run as administrator”.</p>
            <Image images={[answer3_1_6_2_2, answer3_1_6_2_2_1]}/>
            <p> 3) After the Command Prompt have started, please type in “cd C:\HKJC_DM\django_web\hkjc_dm” and Press Enter.</p>
            <Image images={[answer3_1_6_2_3]}/>
            <p> 4) Then type in “workon hkjc_dm” and Press Enter.</p>
            <Image images={[answer3_1_6_2_4]}/>
            <p> 5) Then type in “pip install PyPDF2==2.10” and Press Enter.</p>
            <Image images={[answer3_1_6_2_5]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer3_1_7',
    message: '若不成功安裝 DMP 或 CloudSAMS connection, web-based DMP 是不能用。要從 CloudSAMS connection 獲取數據匯入 MSSQL，收集的數據通過網頁版 DMP 這個平台使用，任何一個步驟都不可缺少。',
    trigger: 'welcome',
}, {
    id: 'answer3_1_8',
    message: '正常， Log folder 可以檢查有否錯誤。每次升級都會看。',
    trigger: 'welcome',
},
{
    id: 'answer3_2_1',
    component: (
        <div>
            <p>Go to the folder “Installation” as shown:</p>
            <Image images={[answer3_2_1]} />
        </div>
    ),
    trigger: 'welcome',
}, 
{
    id: 'answer3_2_2',
    component: (
        <div>
            <p>Go to the folder “settings_backup” as shown:</p>
            <Image images={[answer3_2_2]} />
        </div>
    ),
    trigger: 'welcome',
},
{
    id: 'answer3_2_3',
    component: (
        <div>
            <p>Sequence of installation: Suggest 用 VM 全新安裝 1.7.4，右邊是正式部署的 1.7.4（從 1.1版》1.3》1.4》1.5.3》1.6》1.7.2》1.7.3》1.7.4 升級上去一切順利。</p>
            <p>&#9670; 全新安裝沒有學生 data，P68 頁一步步檢查按教程設定配置。</p>
            <p>&#9670; 如果你是1.7.3想跳過1.7.4 不需要執行 deploy_sql.bat。但是我通常都建議你一個個版本升級上。升級完要檢查 1.web.config 2.hkjc_dm/settings_env.ini 及 hkjc_dm/settings_school.py。如果不是會出 500 error。</p>
            <p>&#9670; 若是 1.5.0版本，不可以跳版本升級，涉及數據升級更新。如果你備份好數據庫及檔案，後面全新安裝 1.7.5也是可以。但是要重新下載全部數據。問題是如果你現在的版本有人用過，數據無法過渡到 1.7.5。你有沒有另一部 DM Server？有的話可以做全新安裝，保留住你現在的舊 DM Server。你要保留舊 DM Server 資料不要丟失。除非完全沒人用過，那你可以放心全新安裝，如果有人用過個 DMP，而你又不保留，造成數據丟失，就要附上責任。</p>
        </div>
    ),
    trigger: 'welcome',
},
{
    id: 'answer3_3_1',
    component: (
        <div>
            <p>After logging-in to PolyU MS Team account and select the “Files” at the top menu bar.  Click “Online Training” folder and choose the workshop that you would like to view.</p>
            <Image images={[answer3_3_1]}/>
        </div>
    ),
    trigger: 'welcome',

},
{
    id: 'answer3_3_2',
    message: '演示片段的 Link 會放在 group chat 裏面, 可直接下載或在 Youtube 看。',
    trigger: 'welcome',
},
{
    id: 'answer3_3_3',
    component: (
        <div>
            <p>那些影片的 link 我們都放在 PPT 裏，你們只需要點擊那條 Link, 就會看到所有的影片。</p>
            <p>&#9670; Recording 和 PPT 我們都會放在 MS Team Group 裏面，進到裏面，會看到「團隊」這個按鈕，點擊 「團隊」按鈕，就會看到「Grp_COMP_賽馬會「校本多元」計劃（Training）」,點擊後你可以看到裏面的 Link; 我們會將所有的會議 Link 都放在「貼文」這裏；我們亦會把 PPT 放在「檔案」裏，裏面有個 Year 1 Registered Schools 文件夾。Video 會放在 recordings folder 內。</p>
            <p>&#9670; 註冊加入了我們的 MS Team, 用你的郵箱就可以登錄進來看到我們 IT Support Team 資料。</p>
            <p>&#9670; 如果你有任何疑問，也可以在「貼文」處打出來，那我們的 IT Support Team 就會盡快回覆大家。在 group chat 裏的溝通，所有老師們都可以看見，也方便大家一起交流經驗。</p>
        </div>
    ),
    trigger: 'welcome',
},
{
    id: 'answer3_3_4',
    message: '可以的。',
    trigger: 'welcome',
},
{
    id: 'answer3_3_5',
    component: (
        <div>
            <p><a href='https://youtu.be/9sIHKUVf47s' target={'_blank'}>https://youtu.be/9sIHKUVf47s</a>(影片來源︰潮州會館中學)</p>
        </div>
    ),
    trigger: 'welcome',
},
];

const DMP_Function_3_1 = [
    {
        id: 'DMP AI Function',
        options: [
            {
                value: '0.1',
                label: 'DMP平台提供哪些AI功能？',
                trigger: 'answer0_1'
            },
            {
                value: '0.2',
                label: '如何使用DMP平台上的AI功能？',
                trigger: 'answer0_2'
            },
            {
                value: '0.3',
                label: '我們是否需要具備編程或技術背景才能使用AI功能？',
                trigger: 'answer0_3'
            },   
            {
                value: '0.4',
                label: '什麼是學生校內成績的預測模組？',
                trigger: 'answer0_4'
            },
            {
                value: '0.5',
                label: '什麼是學生DSE考試成績的預測模組？',
                trigger: 'answer0_5'
            },
            {
                value: '0.6',
                label: '什麼是學生的行為危機預測模組？',
                trigger: 'answer0_6'
            },
            {
                value: '0.7',
                label: '什麼是學生才能辨識及預測的模組？',
                trigger: 'answer0_7'
            },
        ]
    }, {
        id: 'answer0_1',
        message: 'DMP平台提供多種AI功能，包括學生校內成績的預測、DSE考試成績的預測、學生的行為危機預測和學生才能辨識及預測。這些功能可以幫助學校發現學生的強弱項、未來的成績趨勢、可能存在的危機和才能。',
        trigger: 'welcome',
    }, {
        id: 'answer0_2',
        message: '使用DMP平台上的AI功能非常簡單。我們提供用戶友好的界面和文件，以指導學校教職員工如何使用這些功能。您首先需要聯絡我們安裝DMP平台，然後進一步聯絡我們安裝和使用AI功能。我們亦提供相應AI功能的使用培訓。聯繫方式：jcdm.support@polyu.edu.hk。',
        trigger: 'welcome',
    }, {
        id: 'answer0_3',
        message: '不需要。我們的平台旨在為非技術人員提供易於使用的界面和工具，使他們能夠輕鬆使用AI功能。您無需具備編程或技術背景，即可開始使用平台上的AI功能。',
        trigger: 'welcome',
    }, {
        id: 'answer0_4',
        message: '學生校內成績的預測模組是一種利用機器學習或其他預測技術來預測學生在學校內特定科目或課程中的成績表現的模組。它基於學生的過去表現和其他因素，提供一個預測結果，該結果表明學生在未來的考試或課程中可能取得的成績水平。這可以幫助學校和教育機構更好地了解學生的學習狀況，提前做出干預或支援措施，以提高學生的學業成績和學習成效。學生校內成績的預測模組可以提供教育者、家長和學生本身更好的規劃和管理學習進程，並為教育決策提供有價值的參考。然而，需要注意的是，預測模組僅提供預測結果，並不一定完全準確，學生的實際成績還受到其他因素的影響，如努力程度、學習環境和其他個人因素。',
        trigger: 'welcome',
    }, {
        id: 'answer0_5',
        component: (
            <div>
                <p>學生DSE考試成績的預測模組是一種專門用於預測學生香港中學文憑試（DSE）考試成績的模組。它利用機器學習算法，基於學生的過去學業成績數據進行建模和訓練，預測學生在中英數三個主科目的DSE考試中可能獲得的成績。</p>
                <p>這樣的預測模組可以幫助學校、家長和學生自我評估學生的考試表現，並提供對未來DSE考試結果的預測。這樣的預測結果可以作為學生制定升學計劃、選擇適合的大學專業或制定學習策略的參考依據。</p>
                <p>需要注意的是，這樣的預測模組僅提供預測結果，並不能完全準確預測學生的實際考試成績。學生的努力程度、考試準備和其他不可測因素仍然是影響考試成績的重要因素。此外，DSE考試本身也可能有變動和不確定性，因此預測模組的結果僅供參考，學生仍需要全力以赴努力學習和準備考試。</p>
            </div>
        ),
        trigger: 'welcome',
    }, {
        id: 'answer0_6',
        component: (
            <div>
                <p>學生的行為危機預測模組是一種利用數據分析和機器學習技術來預測學生可能面臨行為危機的模組。這樣的模組旨在識別那些可能出現行為問題、違規行為或其他危險行為的學生，以便及早採取干預措施和提供必要的支持。</p>
                <p>學生的行為危機預測模組基於學生的處分記錄、課外活動、出勤記錄、功課提交記錄。這些數據被用作特徵，並通過機器學習算法或其他預測模型進行分析和建模。模型可以識別出具有高風險概率的學生。</p>
                <p>這樣的預測模組可以幫助學校、教育機構和教育工作者更早地識別出潛在的行為危機學生，以便提供適切的支持和干預措施。這些支持措施可以包括行為管理計劃、個別輔導、家長合作等，以幫助學生調整行為, 預防危機。</p>
                <p>然而，需要注意的是，這樣的預測模組僅提供預測結果，並不能完全準確預測學生的實際行為。學生的個體差異、外部環境因素和其他未知變量仍然可能影響學生的行為表現。因此，在應用這樣的預測模組時，應該謹慎且尊重個人隱私和倫理原則。</p>
            </div>
        ),
        trigger: 'welcome',
    }, {
        id: 'answer0_7',
        component: (
            <div>
                <p>學生才能辨識及預測的模組是一種用於辨識和預測學生的才能、天賦和潛能的模組。這樣的模組旨在通過數據分析和機器學習技術，分析學生的教育記錄、學術成績、興趣嗜好、比賽獲獎情況等多種數據，以確定學生的優勢領域、潛在的職業興趣和發展方向。</p>
                <p>它可以幫助學校、教育機構和教育工作者更好地了解學生的才能和潛能，從而提供相應的教育和職業規劃建議。這些模組可以幫助學生選擇適合的課程、專業方向，並提供相應的培訓和發展機會，以最大程度地發揮他們的潛力。</p>
                <p>然而，需要注意的是，這樣的模組僅提供預測結果，並不能完全準確預測學生的實際才能和發展。學生的興趣、動機、個人努力和外部環境等因素也將對他們的發展產生重要影響。此外，學生的興趣和發展方向可能會隨時間變化，因此這樣的預測結果應該被視為一種參考，而非絕對的預測結果。最終的職業和教育選擇應該由學生本人和相關利害關係者共同考慮和決定。</p>
            </div>
        ),
        trigger: 'welcome',
    }
];


const SAMS_Related_4_1 = [{
    id: 'SAMS Related',
    options: [
        { value: 'CloudSAMS', label: 'CloudSAMS', trigger: 'CloudSAMS' },
        { value: 'Security WebSAMS', label: 'Security WebSAMS', trigger: 'Security WebSAMS' },
        { value: 'Data Transfer WebSAMS', label: 'Data Transfer WebSAMS', trigger: 'Data Transfer WebSAMS'},
    ]
}, {
    id: 'CloudSAMS',
    options: [
        { value: '4.1.1.1', label: 'Is it possible to remotely access the CloudSAMS server at home? For example, the teacher does not need to go back to school for accessing the CloudSAMS server.', trigger: 'answer4_1_1_1' },
        { value: '4.1.1.2', label: 'Is it possible to use the DMP without using WebSAMS / CloudSAMS? Say, manually importing data to the SQL Server.', trigger: 'answer4_1_1_2' },
        { value: '4.1.1.3', label: 'Why did I fail to extract data from the WebSAMS / CloudSAMS?', trigger: 'answer4_1_1_3' },
        { value: '4.1.1.4', label: 'CloudSAMS cannot connect or load to the SQL Server', trigger: 'answer4_1_1_4' },
        { value: '4.1.1.5', label: 'A message “Total 10 error occurred” in WebSAMS/CloudSAMS', trigger: 'answer4_1_1_5' },
        { value: '4.1.1.6', label: '某些 source 是不一定在 WebSAMS/CloudSAMS，因為我們學校是用自己的系統，那可以怎麽做呢？', trigger: 'answer4_1_1_6' },
        { value: '4.1.1.7', label: 'CloudSAMS 是用 VPN 作為 connection，那 VPN 還 export data 時用是嗎？', trigger: 'answer4_1_1_7' },
        { value: '4.1.1.8', label: '我校若未成功 install DMP 或 CloudSAMS connection 有問題，可否直接用到 web-based DMP?', trigger: 'answer4_1_1_8' },

    ]
}, {
    id: 'answer4_1_1_1',
    message: 'No, the teacher cannot remotely access the CloudSAMS server at home.  After connecting the VPN, the remote-control software (AnyDesk / Teamviewer) will be disconnected.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_2',
    message: 'It might be feasible, but it is tedious.  For details, you need to inspect the schema in the SQL Server, which we have provided in the installation folder.  Then, you can modify your data and fit the schema structure.  Nevertheless, it is recommended to use the DMP system with either WebSAMS or CloudSAMS.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_3',
    message: 'The credentials in ODBC settings are incorrect (at least one of the parameters), you need to double-check whether they are correct or not.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_4',
    message: 'When using CloudSAMS, the teacher needs to connect to the VPN via FortiClient VPN first.  For more details, please refer to the Deployment Manual 1.6.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_5',
    message: 'If the teacher can use the DM Server normally, they can ignore this error.  This error is caused by no data in SAMS or difference in SQL Schema.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_6',
    message: '看看你們學校的系統是否有 export 的 function，並 convert 為 CSV file.  如果可以的話，就可以上傳到我們 DM server 裏面。',
    trigger: 'answer4_1_1_6_1',
}, {
    id: 'answer4_1_1_6_1',
    message: 'CSV 是一個 standard format，也方便我們把有關的 data import 進去DMP。',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_7',
    message: 'VPN 主要是 connect 上教育局的 network, 因為教育局的 network 只有用VPN 才可以 connect, VPN 只是起 connect 作用。',
    trigger: 'answer4_1_1_7_1',
}, {
    id: 'answer4_1_1_7_1',
    message: '如果你不是用 CloudSAMS 的話，就可以不用 VPN。',
    trigger: 'welcome',
}, {
    id: 'answer4_1_1_8',
    message: '若不成功安裝 DMP 或 CloudSAMS connection, web-based DMP 是不能用。要從 CloudSAMS connection 獲取數據匯入 MSSQL，收集的數據通過網頁版 DMP 這個平台使用，任何一個步驟都不可缺少。',
    trigger: 'welcome',
}, {
    id: 'Security WebSAMS',
    options: [
        { value: '4.1.2.1', label: 'Why do we need to get sensitive data when extracting data from WebSAMS?', trigger:'answer4_1_2_1' },
        { value: '4.1.2.2', label: 'There are concerns about security and privacy of SAMS data on DM server, especially leakage via Power BI and web applications.  How to locate a DM server at the school network segment to strengthen the security?',trigger:'answer4_1_2_2' },
        { value: '4.1.2.3', label: "What is the security of WebSAMS data? (If the data is stored in one of school colleague’s laptop, it will be exposed to risks like Ransomware or computer virus?)", trigger:'answer4_1_2_3' },
        { value: '4.1.2.4', label: "EdB changed the downloading method of WebSAMS data in 2022.  是不是 11 月後會轉？", trigger:'answer4_1_2_4' },
    ],
}, {
    id: 'answer4_1_2_1',
    message: "Our intention for data extraction is not these sensitive data. For example, when we want to extract student data (e.g. their name as ID) for data management / analytics, their addresses or names of their parents are also in the same table.  Instead of extracting partial data from a table, you can encrypt the sensitive data because it is more convenient",
    trigger: 'welcome',
}, {
    id: 'answer4_1_2_2',
    component: (
        <div>
            <p>In all our settings, the DMP is deployed for intranet assessment.  We do not recommend making DM services be accessed outside school using a public network.  Within the school intranet, there is no risk of data leakage.  The DM server only can be accessed inside a school.  In addition, the DM server does not connect to WebSAMS daily.  Every user (teacher) requires ID/password to connect to DM web services.  In order to avoid security leak in school network, we need to check if HTTPS (encrypted messages) have been enabled.  If a school has 2 intranets, one is teacher/admin zone and the other is student zone.  To strengthen security measure, DM web services is to place the teacher/admin zone for minimizing risks</p>
            <p>When downloading/transfering from WebSAMS to DMP, a school admin needs to provide ID/password.  The DM server will NOT have the WebSAMS Id/password.</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_1_2_3',
    message: 'We are against storing data outside the school intranet.  As long as the data is kept by trusted individuals WITHIN the school network, it will most likely be safe.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_2_4',
    message: 'Yes. EdB had sent out the notice to all schools that ODBC will not be supported for downloading WebSAMS data via EdB VPN with effect from 1 Jan 2023. In “Deployment Manual 2” issued on April 2023 ver 5, regarding the connection to WebSAMS for downloading data, please refer to section 2D step 4.',
    trigger: 'welcome',
}, {
    id: 'Data Transfer WebSAMS',
    options: [
        
        { value: '4.1.3.2', label: 'When the teacher attempts to connect to the SAMS database, the database server cannot be found.', trigger:'answer4_1_3_2' },
        { value: '4.1.3.3', label: 'Will the data automatically transfer from WebSAMS to DM Platform?', trigger:'answer4_1_3_3' },
        { value: '4.1.3.4', label: '學校 SAMS 裏無 academic data, 是否有其他方法可直接把 academic data 轉到 DMP？', trigger:'answer4_1_3_4' },
        { value: '4.1.3.5', label: 'WebSAMS Server and DM Server 之間，DM Server 不可以改 WebSAMS 裏面的資料呢?', trigger:'answer4_1_3_5' },
        { value: '4.1.3.6', label: 'Some SAMS academic year data is missing from Power BI, is this normal?', trigger:'answer4_1_3_6' },
        { value: '4.1.3.7', label: 'Load 到 DMP 但無資料出嚟, 係 SAMS 拎到資料 (no detail seen in Power BI)', trigger:'answer4_1_3_7' },
        { value: '4.1.3.8', label: 'Failed when clicking test connection [to SAMS data].', trigger:'answer4_1_3_8' },
    ]
},  {
    id: 'answer4_1_3_2',
    component: (
        <Image images={[answer4_1_3_2]}/>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_1_3_3',
    message: 'The data can be automatically transferred from WebSAMS to DMP, but it needs configuration of Powershell and Windows task scheduler.  The instructions for configuration are in our deployment guideline, which can be found in Section C.1 (P 68).',
    trigger: 'welcome',
}, {
    id: 'answer4_1_3_4',
    component: (
        <div>
            <p>有 WebSAMS 和 CloudSAMS 兩個系統，其中 WebSAMS 上的 data 是自動下載的，CloudSAMS 上的 data 是需要手動下載;</p>
            <p>如果需要 CloudSAMS，就需要去申請教育局的 VPN assess right, 申請成功後，你會收到 user name 和password。Connect 完成後，就需要人手去 CloudSAMS export data 去到 DM Server。然後你再進入我們的 DMP 就會看到相關的數據。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_1_3_5',
    message: '應該是不能改的，我們主要是把數據從 WebSAMS 或 CloudSAMS export 出來，然後 import 入 school DM Server。',
    trigger: 'welcome',
}, {
    id: 'answer4_1_3_6',
    message: 'Please contact your WebSAMS coordinator to download WebSAMS data in 2022 using the original method.',
    trigger: 'welcome',
}, {
    id: 'answer4_1_3_7',
    component: (
        <div>
            <p>需要執行這段code (in “Operation Manual”, section before 7.4) ，沒有匯入到數據庫，Power BI 自然空白一片。</p>
            <Image images={[answer4_1_3_7]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_1_3_8',
    message: '首次使用要做初始化, 因為如果 .\initialload.ps1係 cloudSAMS 既同工都要做，要提醒。',
    trigger: 'welcome',
}];

const System_Spec_4_2 = [{
    id: 'System Spec',
    options: [
        { value: '4.2.1', label: 'What is the specification requirement for the computer for DM System? Do we need a strong computer to use it?', trigger:'answer4_2_1' },
        { value: '4.2.2', label: 'My Windows 10 version is old and is run on virtual environment. Can I use it to run DM Server instead of physical computer?', trigger:'answer4_2_2' },
        { value: '4.2.3', label: '如果學校有 VM server，可否裝你們 DM 系統，是否還需要獨立一臺裝 DM 系統？', trigger:'answer4_2_3' },
        { value: '4.2.4', label: '我校的 OS 是 Windows Server 是 2016 年版本，用的 DB 是不是一定要裝 SQL Server 7.0 呢？', trigger:'answer4_2_4' },
        { value: '4.2.5', label: '如果買新機的話，是不是要裝最新的 Window Server，還是跟回你的 server recommendation, 一定要 Windows 2016 搭 SQL Server 7.0 呢？', trigger:'answer4_2_5' },
        { value: '4.2.6', label: '我校 OS 更舊，系統是 2012 年 version 的，請問可以嗎？', trigger:'answer4_2_6' },
        { value: '4.2.7', label: 'Is it possible to use OS Windows Server 2016 for the DMP?', trigger:'answer4_2_7' },
    ]
}, {
    id: 'answer4_2_1',
    component: (
        <div>
            <p>The recommended hardware requirements are below:</p>
            <Image images={[answer4_2_1_1]}/>
            <p>The recommended software requirements are below:</p>
            <Image images={[answer4_2_1_2]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_2_2',
    message: 'For Windows 10 version, the version does not matter.  You can still run the DM System on a Windows 10 OS with older version.  For the virtual environment, it is feasible.  However, we suggest using physical computer, because virtual environment is slower and unstable.',
    trigger: 'welcome',
}, {
    id: 'answer4_2_3',
    component: (
        <div>
            <p>最好是能獨立一臺，因為 VM server 也有自己的功能；</p>
            <p>&#9670; 但如果你希望用 VM server 來裝，也是可以的，只是因為 DM 系統數據比較大，如果都在 VM server 運作的話，可能會比較緩慢，不一定能及時滿足你們的 data 需求，建議有獨立的 DM server 會更為穩妥。</p>
            <p>&#9670; 但如果財務有限的話，只能用 VM Server 的話，暫時也是可以的, 只不過是速度會慢些，老師們可使用的時間就會比較少。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_2_4',
    message: '如果只是需要 DM1.0 系統的基本模塊功能理論上你現在 2016 年版本是可以滿足的，但接下來的 DM2.0 version 和未來我們 upgrade 模塊，以及結合 AI，那就不一定能支持。',
    trigger: 'welcome',
}, {
    id: 'answer4_2_5',
    component: (
        <div>
            <p>目前在 DM1.0 裏，用這兩個是可以的；</p>
            <p>&#9670; 未來如果有推出新模塊的話，會建議需要 upgrade；如果我們有新的模塊的話，我們會通知你們學校建議 upgrade server.</p>
            <p>&#9670; 但如果我們還沒有任何通知的話，暫時用目前的系統也是可以的。這一年暫時未計劃重新推出新的模塊，所以你們這段時間用 windows 2016 版本也是可以的。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_2_6',
    component: (
        <div>
            <p>如果只需要 DMP 基本模塊的話, 是可以滿足的；但未來推出新模塊的話，就不一定能支持。</p>
            <p>&#9670; 我們也有考慮在 cloud 上做，這也是我們很重要的考慮，如果可以在 cloud solution 上實現的話，好處是所有 software 你們是不需要下載的, 是可以在 cloud access 上直接去做的就可以了。也就不存在 license 的問題，因為已經在 cloud 裏面允許的了，那將來的 cloud solution 對所有學校的 set up 就輕便了很多。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_2_7',
    message: 'It is possible.',
    trigger: 'welcome',
}];

const Security_4_3 = [{
    id: 'Security',
    options: [
        { value: '4.3.0', label: '我們的學生數據是否會受到保護？', trigger:'answer4_3_0' },
        { value: '4.3.1', label: 'There are concerns about security and privacy of SAMS data on DM server, especially leakage via Power BI and web applications. How to locate a DM server at the school network segment to strengthen the security?', trigger:'answer4_3_1' },
        { value: '4.3.2', label: 'What is the security of WebSAMS data? If the data is stored in one of school colleague’s laptop, it will be exposed to risks like Ransomware or computer virus?', trigger:'answer4_3_2' },
        { value: '4.3.3', label: 'Can every teacher access the DM Server data easily?', trigger:'answer4_3_3' },
        { value: '4.3.4', label: 'The SQL Server password is forgotten or lost, how to change?', trigger:'answer4_3_4' },
        { value: '4.3.5', label: 'In the future, will there be security issues related to DM 2.0? Because students and their parents may have access to the WebSAMS data.', trigger:'answer4_3_5' },
        { value: '4.3.6', label: '是不是每一位老師看到的版面是不是都一樣的呢？', trigger:'answer4_3_6' },
        { value: '4.3.7', label: '是不是在學校才可以進入 DMP 呢？還是在家裏也可以加入呢？', trigger:'answer4_3_7' },
        { value: '4.3.8', label: 'FortiToken200 無電，點搞?', trigger:'answer4_3_8' },
        { value: '4.3.9', label: '我校認為將 Power BI file 派給同事，讓他們在自己電腦的 Power BI 軟件開啟，會有好高的資料洩漏風險。因此不贊成為同事安裝 Power BI 軟件，想請問有沒有甚麼措施能減少風險呢？', trigger:'answer4_3_9' },
        { value: '4.3.10', label: '請問老師可否自行在自己的電腦瀏覽還是有一部特定電腦讓老師前去瀏覽 Power BI reports 呢？', trigger:'answer4_3_10' },
        { value: '4.3.11', label: 'Considerations when using TeamViewer to allow remote assistance by PolyU.', trigger:'answer4_3_11' },
    ],
}, {
    id: 'answer4_3_0',
    message: 'DMP平台非常重視學生數據的保護和隱私。我們遵守相關的數據保護法規，並採取安全措施來保護學生數據的機密性和完整性。您可以放心使用我們的平台，我們將確保您的數據安全。',
    trigger: 'welcome',
},{
    id: 'answer4_3_1',
    message: 'In all our settings, the DMP is deployed for intranet assessment.  We do not recommend making DM services be accessed outside school using a public network.  Within the school intranet, there is no risk of data leakage.  The DM server only can be accessed inside a school.  In addition, the DM server does not connect to WebSAMS daily.  Every user (teacher) requires ID/password to connect to DM web services.  In order to avoid security leak in school network, we need to check if HTTPS (encrypted messages) have been enabled.  If a school has 2 intranets, one is teacher/admin zone and the other is student zone.  To strengthen security measure, DM web services is to place the teacher/admin zone for minimizing risks.',
    trigger: 'answer4_3_1_1',
}, {
    id: 'answer4_3_1_1',
    message: 'When downloading/transferring from WebSAMS to DMP, a school admin needs to provide ID/password.  The DM server will NOT have the WebSAMS Id/password.',
    trigger: 'welcome',
}, {
    id: 'answer4_3_2',
    message: 'We are against storing data outside the school intranet.  As long as the data is kept by trusted individuals WITHIN the school network, it will most likely be safe.',
    trigger: 'welcome',
}, {
    id: 'answer4_3_3',
    message: 'For Power BI module, teachers just need to install Power BI (Free!) on their computers.  Then, these up-to-date Power BI files (.pbix) can be copied and pasted to their computers easily.  However, these files are view-only.  If they would like to obtain the latest data, they need to download a copy from the DM Server computer again.',
    trigger: 'answer4_3_3_1',
}, {
    id: 'answer4_3_3_1',
    message: 'For the DM System, teachers would need to install the whole DM System to access the data.',
    trigger: 'welcome',
}, {
    id: 'answer4_3_4',
    message: 'Unfortunately, once you set the SQL Server password, it cannot be changed UNLESS you know the old password.  For security reason, it will be vulnerable for data theft if SQL Server password can be changed easily.  As a result, we recommend uninstalling and reinstalling all components related to SQL Server.',
    trigger: 'welcome',
}, {
    id: 'answer4_3_5',
    message: 'In the DM System, there will be user accounts with different levels of permissions.  For students and their parents, they can only access their own scores.  For class teachers, they can only access their classes’ scores.  The permission structure is well-defined and will not have security issues.',
    trigger: 'welcome',
}, {
    id: 'answer4_3_6',
    message: '是的，暫時沒有分別。',
    trigger: 'welcome',
}, {
    id: 'answer4_3_7',
    message:'DM Server 需要裝在 貴校內，與學校網絡 link 一起，DM Server 管理人員給老師們權限，賬戶和密碼, 就可以進入。',
    trigger: 'welcome',
}, {
    id: 'answer4_3_8',
    message: 'The FortiToken is manufactured using a tamper-resistant packaging and it is not possible to change the battery.  Once a battery reaches the end of life, it is necessary to replace the FortiToken.',
    trigger: 'welcome',
}, {
    id: 'answer4_3_9',
    message: '目前只限指定老師使用，一個 WebSAMS 負責人，另一個項目負責人, 只在特定日子供老師瀏覽部分數據, 比如家長日，一年只有兩次。',
    trigger: 'welcome',
}, {
    id: 'answer4_3_10',
    message: '按校情自行決定, 洩露數據有法律風險，慎重。越少人接觸越安全。',
    trigger: 'welcome',
}, {
    id: 'answer4_3_11',
    component: (
        <div>
            <p>Sᴘᴇᴄɪᴀʟ Cᴏɴꜱɪᴅᴇʀᴀᴛɪᴏɴꜱ Wʜᴇɴ Uꜱɪɴɢ TᴇᴀᴍVɪᴇᴡᴇʀ – 2 options:-</p>
            <p>Only start TeamViewer when there is a need to use it. Quit TeamViewer and shut it down immediately after using it. Even better, TeamViewer should be disabled from automatically starting up after starting up the server. Whitelist only the remote systems that are trusted to be connected. If feasible, two-factor authentication should be enabled.</p>
            <p>When running the TeamViewer setup, user can select “Run Only (One-Time Use)”, then TeamViewer will not be installed and just for one-time use.</p>
            <p>使用 TeamViewer 時的特殊注意事項 – 2 個選項 ：</p>
            <p>僅在需要使用 TeamViewer 時才啟動它。 使用後，請立即退出並關閉 TeamViewer。更好方案 : 在啟動伺服器後，應禁止 TeamViewer 自動啟動。僅將可信任連線的遠端系統列入白名單。 如果可行，應啟用雙重認證。</p>
            <p>執行 TeamViewer 安裝程式時，使用者可以選擇「僅執行（一次性使用）」，則 TeamViewer 將不會被安裝，僅用於一次性使用。</p>
        </div>
    ),
    trigger: 'welcome',
}];

const Server_Web_Config_4_4 = [{
    id: 'Server/Web Config',
    options: [
        { value: 'Server', label: 'Server',trigger:'Server' },
        { value: 'Web', label: 'Web',trigger:'Web' },
    ],
}, {
    id: 'Server',
    options: [
        { value: '4.4.1.1', label: 'Is it possible to use OS Windows Server 2016 for the DMP?', trigger:'answer4_4_1_1' },
        { value: '4.4.1.2', label: 'Do we have to install the DM Server or Microsoft Windows Server 2016?  Can we install it on Windows 10?', trigger:'answer4_4_1_2' },
        { value: '4.4.1.3', label: 'Can every teacher access the DM Server data easily?', trigger:'answer4_4_1_3' },
        { value: '4.4.1.4', label: '我學的 OS 是 Windows Server 是 2016 年版本，用的 DB 是不是一定要裝 SQL Server 7.0 呢？', trigger:'answer4_4_1_4' },
        { value: '4.4.1.5', label: 'CloudSAMS cannot connect or load to the SQL Server.', trigger:'answer4_4_1_5' },
        { value: '4.4.1.6', label: 'WebSAMs Server and DM Server 之間，DM Server 不可以改 WebSAM 裏面的資料呢?', trigger:'answer4_4_1_6' },
        { value: '4.4.1.7', label: '如果學校有 VM server，可否裝你們 DM 系統，是否還需要獨立一臺裝 DM 系統？', trigger:'answer4_4_1_7' },
        { value: '4.4.1.8', label: '如果買新機的話，是不是要裝最新的 Window Server，還是跟回你的 server recommendation, 一定要 Windows 2016 搭 SQL Server 7.0 呢？', trigger:'answer4_4_1_8' },
        { value: '4.4.1.9', label: '如果裝 DM Server, 我們就會知道學生在不同階段不同學校的表現，是否也可以看到呢？', trigger:'answer4_4_1_9' },
        { value: '4.4.1.10', label: 'Cannot save hosts file.', trigger:'answer4_4_1_10' },
    ],
}, {
    id: 'Web',
    options: [
        { value: '4.4.2.1', label: 'How do I setup the DM website for the whole school (within the school LAN )? The DM Server can only be opened in the host computer, but not by other computers in the same domain (within the LAN network).', trigger:'answer4_4_2_1' },
        { value: '4.4.2.2', label: 'DM Server 與 Web Server 之間的關系是怎麽樣的呢？', trigger:'answer4_4_2_2' },
        { value: '4.4.2.3', label: 'HTTP500 error?', trigger:'answer4_4_2_3' },
        { value: '4.4.2.4', label: 'Click http://hkjcdm.com/ 時無法連上', trigger:'answer4_4_2_4' },
    ],
}, {
    id: 'answer4_4_1_1',
    message: 'It is possible.',
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_2',
    message: 'Windows 10/11 is the recommended OS for DM System.',
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_3',
    message: 'For Power BI module, teachers just need to install Power BI (Free!) on their computers.  Then, these up-to-date Power BI files (.pbix) can be copied and pasted to their computers easily.  However, these files are view-only.  If they would like to obtain the latest data, they need to download a copy from the DM Server computer again.',
    trigger: 'answer4_4_1_3_1',
}, {
    id: 'answer4_4_1_3_1',
    message: 'For the DM System, teachers would need to install the whole DM System to access the data.',
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_4',
    message: '如果只是需要 DM1.0 系統的基本模塊功能理論上你現在 2016 年版本是可以滿足的，但接下來的 DM2.0 version 和未來我們 upgrade 模塊，以及結合 AI，那就不一定能支持。',
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_5',
    message: 'When using CloudSAMS, the teacher needs to connect to the VPN via FortiClient VPN first.  For more details, please refer to the Deployment Manual 1.6.',
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_6',
    message: '應該是不能改的，我們主要是把數據從 WebSAMS 或 CloudSAMS export 出來，然後 import 入 school DM Server。',
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_7',
    component: (
        <div>
            <p>最好是能獨立一臺，因為 VM server 也有自己的功能；</p>
            <p>&#9670; 但如果你希望用 VM server 來裝，也是可以的，只是因為 DM 系統數據比較大，如果都在 VM server 運作的話，可能會比較緩慢，不一定能及時滿足你們的 data 需求，建議有獨立的 DM server 會更為穩妥。</p>
            <p>&#9670; 但如果財務有限的話，只能用 VM Server 的話，暫時也是可以的, 只不過是速度會慢些，老師們可使用的時間就會比較少。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_8',
    component: (
        <div>
            <p>目前在 DM1.0 裏，用這兩個是可以的；</p>
            <p>&#9670; 未來如果有推出新模塊的話，會建議需要 upgrade；如果我們有新的模塊的話，我們會通知你們學校建議 upgrade server.</p>
            <p>&#9670; 但如果我們還沒有任何通知的話，暫時用目前的系統也是可以的。這一年暫時未計劃重新推出新的模塊，所以你們這段時間用 windows 2016 版本也是可以的。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_9',
    component: (
        <div>
            <p>目前這些資料主要是由學校保管；</p>
            <p>&#9670; 後續我們會考慮去掉個人敏感訊息，結合 cloud 和 AI 來做系統地分析和預測，比如不同學校之間的比較，如何個性化教學調整等等。</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_4_1_10',
    component: (
        <div>
            <p>取消唯讀權限方可保存，格式是hosts，後面沒有 .txt。可以先用住 IP address，這個 domain只是方便用家記住。用 notepad打開，用 Administrator登入去修改（權限問題），修改完save。</p>
            <p>第二個方法，在桌面建立一個 hosts，改完再覆蓋過去。hosts檔案要用自己 Server IP 不要用 127.0.0.1，如果不是會出 Not found 404。學校可以加一個 domain，例如 dmserver.XXX.edu.hk，再指向學校部 DM Server。如果選擇用 IP 的方案後面 IIS 該處不要打 hkjcdm.com.</p>
            <Image images={[answer4_4_1_10]}/>
            <p>执行完 pip install virtualenvwrapper-win 及 mkvirtualenv hkjc_dm 已經可以 run workon hkjc_dm.</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_4_2_1',
    component: (
        <div>
            <p>The host computer needs to be connected to the LAN network.</p>
            <Image images={[answer4_4_2_1]}/>
        </div>
    )
}, {
    id: 'answer4_4_2_2',
    message: '主要是從 WebSAMS or CloudSAMS export data 到 DM Server. 如果學校沒有相關 WebSAMS/CloudSAMS 數據，可以向教育局申請。',
    trigger: 'welcome',
}, {
    id: 'answer4_4_2_3',
    message: 'HTTP500 is the fastcgi / web.config problem.  To fix this, disable fastcgi in cmd, then enable the fastcgi again.  Do not close the cmd and copy and path provided by the result.  Paste the result (refer to deployment manual) to the web.config in hkjc_dm (p.58, Deployment Manual, version 1.7, Aug 2022).',
    trigger: 'welcome',
}, {
    id: 'answer4_4_2_4',
    component: (
        <div>
            <p>可以直接用IP address:</p>
            <Image images={[answer4_4_2_4_1]}/>
            <p>Check web config:</p>
            <Image images={[answer4_4_2_4_2]}/>
            <p>Also ensure the following is correct:</p>
            <Image images={[answer4_4_2_4_3]} />
            <p>如果你想用hkjcdm.com的話，IP要改為你Server IP address。</p>
            <p>因為domain指向了錯誤IP 會導致Not Found error 404。</p>
            <p>要用hkjcdm.com 不要用127.0.0.1，要用自己server IP。</p>
        </div>
    ),
    trigger: 'welcome',
}];

const SQL_4_5 = [{
    id: 'SQL',
    options: [
        { value: 'SQL / MySQL', label: 'SQL / MySQL',trigger:'SQL / MySQL' },
        { value: 'CSV', label: 'CSV', trigger: 'CSV' },
    ],
}, {
    id: 'SQL / MySQL',
    options: [
        { value: '4.5.1.1', label: 'Is it possible to use MySQL instead of SQL Server 2017?', trigger:'answer4_5_1_1' },
        { value: '4.5.1.2', label: 'How to manually Import table to the SQL Server (custom .csv instead of SAMS)?', trigger:'answer4_5_1_2' },
        { value: '4.5.1.3', label: 'CloudSAMS cannot connect or load to the SQL Server.', trigger:'answer4_5_1_3' },
        { value: '4.5.1.4', label: 'The teacher cannot back up the SQL Database', trigger:'answer4_5_1_4' },
        { value: '4.5.1.5', label: '不能連接 Power BI 到 SQL，應該點做？', trigger:'answer4_5_1_5' },
    ],
}, {
    id: 'CSV',
    options: [
        { value: '4.5.2.1', label: '請問怎麼樣 fix 這個 Error？', trigger:'answer4_5_2_1' },
    ],
}, {
    id: 'answer4_5_1_1',
    message: 'It might be possible, but localization will be needed and we did not provide the manual for MySQL.  For convenience, you can use the SQL Server as instructed in the installation manual.  For other similar software, you are free to use them.  However, we may not be able to provide technical support.',
    trigger: 'welcome',
}, {
    id: 'answer4_5_1_2',
    component: (
        <div>
            <p>Currently, it is very difficult to manually import table to the SQL Server, because the attributes are most likely different.  If the teacher would like to manually import the table to the SQL Server, there are two possible ways.</p>
            <p><i>Method 1:</i>Use getExcelData.ps1 (provided by Atos)</p>
            <Image images={[answer4_5_1_2_1,answer4_5_1_2_2]}/>
            <p>However, no teacher has reported whether this method works.</p>
            <p><i>Method 2 </i>might be better: Use SQL Command to import the data.</p>
            <Image images={[answer4_5_1_2_3]}/>
            <p>The above figure is an example of using “BULK INSERT” to import the data to the existing tables in SQL Server.  If the teacher has some SQL coding skills, they can try it.</p>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_5_1_3',
    message: 'When using CloudSAMS, the teacher needs to connect to the VPN via FortiClient VPN first.  For more details, please refer to the Deployment Manual 1.6.',
    trigger: 'welcome',
}, {
    id: 'answer4_5_1_4',
    component: (
        <div>
            <p>The teacher needs to delete the previous backup file before able to back up again. The location of the old backup file (example) is shown below.</p>
            <Image images={[answer4_5_1_4]}/>
        </div>
    ),
    trigger: 'welcome',
}, {
    id: 'answer4_5_1_5',
    message: '用了錯誤網絡或者防火墻封鎖了. 要同一個網絡, 才可以連接上server。學校需要在同一個網絡內才能連接，第二點是檢查防火墻配置有沒有封鎖相關port位。',
    trigger: 'welcome',
}, {
    id: 'answer4_5_2_1',
    component: (
        <div>
            <p>第一檢查 config.json，第二檢查防毒軟件是否有攔截，第三檢查 sql server 設定。用 WebSAMs_csv 1.1.2 版本可以解決這個問題，如果不行就檢查以下幾個地方。</p>
            <Image images={[
                answer4_5_2_1_1,
                answer4_5_2_1_2,
                answer4_5_2_1_3,
                answer4_5_2_1_4,
                answer4_5_2_1_5,
                answer4_5_2_1_6,
                answer4_5_2_1_7]}
            />
        </div>
    ),
    trigger: 'welcome',
}]

const DMP_Technical_Information_4 = [{
    id: 'DMP Technical Information',
    options: [
        { value: 'SAMS Related', label: 'SAMS Related',trigger:'SAMS Related' },
        { value: 'System Spec', label: 'System Spec', trigger: 'System Spec' },
        { value: 'Security', label: 'Security', trigger: 'Security' },
        { value: 'Server/Web Config', label: 'Server/Web Config', trigger: 'Server/Web Config' },
        { value: 'SQL', label: 'SQL', trigger: 'SQL' },
    ]
},
...SAMS_Related_4_1,
...System_Spec_4_2,
...Security_4_3,
...Server_Web_Config_4_4,
...SQL_4_5,
];

const Module_Usage_5 = [{
    id: 'Module Usage',
    options: [
        { value: 'Item Analysis(IAR)', label: 'Item Analysis(IAR)', trigger: 'Item Analysis(IAR)'},
    ],
}, {
    id: 'Item Analysis(IAR)',
    options: [
        { value: '5.1.1', label: 'After pressing “Edit user permission” in the “Item Analysis” (IAR) module, there was error message “ ‘NoneType’ object has no attribute ‘subject_nm_en’ ”.', trigger:'answer5_1_1' },
    ]
}, {
    id: 'answer5_1_1',
    component: (
        <div>
            <Image images={[answer5_1_1]}/>
        </div>
    ),
    trigger: 'welcome',
}];

const initialMessageList = {
    steps: [{
        id: 'welcome',
        message: 'Hi, Please select a topic',
        trigger: 'welcomeOptions',
    }, {
        id: 'welcomeOptions',
        options: [
            // { value: 'Registration/Account/Password', label: 'Registration/Account/Password', trigger: ({value, steps}) => handleDispatch(steps, {type: value})},
            { value: 'Registration/Account/Password', label: 'Registration/Account/Password', trigger: 'Registration/Account/Password' },
            { value: 'Power BI/Dashboard', label: 'Power BI/Dashboard', trigger: 'Power BI/Dashboard' },
            { value: 'DMP AI Function', label: 'DMP AI Function', trigger: 'DMP AI Function' },
            { value: 'DMP Installation & Deployment', label: 'DMP Installation & Deployment', trigger: 'DMP Installation & Deployment'},
            { value: 'DMP Technical Information', label: 'DMP Technical Information', trigger: 'DMP Technical Information'},
            { value: 'Module Usage', label: 'Module Usage', trigger: 'Module Usage'},
            { value: 'Ask Question', label: 'Ask Question', trigger: 'question'},
        ],
    },
    {
        id: 'question',
        user: true,
        trigger: 'customStep'
        // trigger: ({value, steps}) => searchStep(steps, value)
    },
    {
        id: 'question_not_found',
        message: 'Question no found',
        trigger: 'welcome',
    },
    {
        id: 'customStep',
        component:  <CustomStep />,
        waitAction: true,
        // trigger: '2', <-- commented out
    },
    ...Registration_Account_Password_1,
    ...PowerBI_Dashboard_2,
    ...DMP_Function_3_1,
    ...DMP_Installation_Deployment_3,
    ...DMP_Technical_Information_4,
    ...Module_Usage_5,
    ]
};

const App = () => {

    const [messageList, setMessageList] = useState(initialMessageList);

    return (
        <div style={{width: '50%', margin: '20px auto 20px auto', maxWidth: '600px', minWidth: '600px'}}>
            <CustomChatbot
                width="600px"
                height="600px"
                {...messageList}
            />
        </div>
    )
}


export { initialMessageList }
export default App;
