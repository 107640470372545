import { useCallback, useEffect, useReducer, useState } from 'react';
import {initialMessageList as messageList} from './HardcodeChatbot';


const CustomStep = ({ previousStep, triggerNextStep }) => {

    const searchValue = previousStep.value.toLowerCase();
    let buttonList = []
    
    // try to use map/reduce function
    const searchStep = (searchValue) => {
        const optionResult = messageList.steps.filter((step) => !!step.options);
        const results = optionResult.filter(result => result.options.some((option) => option.label.toLowerCase().includes(searchValue)))

        if (results.length > 0) {
            for(let i = 0; i < results.length; i++) {
                const items = results[i].options.filter(option => option.label.toLowerCase().includes(searchValue))
                for (let item of items) {
                    buttonList.push(
                        <li key={`${item.value}-li`} className='sc-htpNat GgOGn rsc-os-option'>
                            <button key={item.value} className="sc-bxivhb ipSaXD rsc-os-option-element" onClick={() => triggerNextStep({ value: item.value, trigger: item.trigger })}>{item.label}</button>
                        </li>
                    )
                }
            }

        } else {
            buttonList.push(
                <li className='sc-htpNat GgOGn rsc-os-option'>
                    <button key="no found" className="sc-bxivhb ipSaXD rsc-os-option-element" onClick={() => triggerNextStep({ trigger: 'question_not_found'})}>Question no found</button>
                    <button key="ask question" className="sc-bxivhb ipSaXD rsc-os-option-element" onClick={() => triggerNextStep({ trigger: 'question'})}>Ask Question</button>
                </li>
            )
        }

    }   
    searchStep(searchValue)

    return (
        <div className={'sc-EHOje jvzENE rsc-os'}>
            <ul className='sc-ifAKCX gkhNlr rsc-os-options'>
                {buttonList}
            </ul>
            
         </div>
    );
};

export default CustomStep;